
/* eslint-disable no-param-reassign */

import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient, IForm,
} from '@/modules/scheduled-reports/interfaces';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import loop24 from '@/modules/common/filters/loop-24.filter';
import { ComplexPair } from '@/modules/scheduled-reports/interfaces/filter-item.interface';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import ScheduledItemModel from '@/modules/scheduled-reports/models/scheduled-item.model';
import RatesService, { RatesServiceS } from '../../rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '../../rates-filters.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../../rates-analysis-filters.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '../../rates-analysis.service';

const keyMap = {
    'past period': 'diffDays',
    source: 'providers',
    mealType: 'meal_type',
    roomType: 'room_type',
    los: 'los',
    pos: 'pos',
    'number of guest': 'occupancy',
    price: 'price',
} as Record<string, string>;

const analysisKeyMap = {
    diffDays: 'past period',
    provider: 'source',
    mealTypeId: 'mealType',
    roomTypeId: 'roomType',
    los: 'los',
    pos: 'pos',
    numberOfGuests: 'number of guest',
    priceType: 'price',
} as Record<string, string>;

const compareList = [
    { value: 'past period', name: 'Past Period' },
    { value: 'source', name: 'Source' },
    { value: 'mealType', name: 'Meal Type' },
    { value: 'roomType', name: 'Room Type' },
    { value: 'los', name: 'LOS' },
    { value: 'pos', name: 'POS' },
    { value: 'number of guest', name: 'Number of guests' },
    { value: 'price', name: 'Price' },
];

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(RatesAnalysisServiceS) ratesAnalysisService!: RatesAnalysisService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    readonly dataType = DATA_TYPE.RATES_COMPARE;

    form: IForm = {} as IForm;

    get properties(): IProperties {
        return {
            dataType: this.dataType,
            dateRange: {
                options: [30, 60, 90],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns() {
        return [];
    }

    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];

        if (this.ratesService.isLoading) {
            return filters;
        }

        this.defineCompsetFilter(filters);
        this.definePosFilter(filters);
        this.defineProviderFilter(filters);
        this.defineLosFilter(filters);
        this.defineMealTypeFilter(filters);
        this.defineRoomTypeFilter(filters);
        this.defineOccupancyFilter(filters);
        this.definePriceTypeFilter(filters);
        this.defineCompareFilter(filters);

        return filters;
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '0',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }

    private get compset() {
        const { filters: values = {} } = this.form;

        return this.compsetsService.getCompset(values.compset as string)
            || this.compsetsService.currentCompset;
    }

    private defineCompsetFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const options = this.compsetsService.compsets!
            .map(compset => ({
                name: compset.name,
                value: compset.id,
            }));

        const choosenCompset = (values.compset
            || this.compsetsService.currentCompset?.id) as string;

        filters.push({
            name: 'compset',
            label: 'Comp Set',
            value: choosenCompset,
            options,
            disableOnEdit: true,
        });
    }

    private defineProviderFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;
        const { compset } = this;

        const providerList = (compset?.rateProviders || [])
            .filter(p => !['all', 'cheapest'].includes(p));

        let choosenProvider = (values.providers
            || this.ratesFiltersService.settings.provider) as string;

        if (!providerList.includes(choosenProvider)) {
            [choosenProvider] = providerList;
            values.providers = choosenProvider;
        }

        filters.push({
            name: 'providers',
            label: 'Source',
            value: choosenProvider,
            options: providerList
                .map(p => ({
                    name: this.providersService.getProviderLabel(p),
                    value: p,
                })),
            disableOnEdit: false,
        });
    }

    private definePosFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;
        const { compset } = this;

        const posItems = compset?.pos
            || [];

        let choosenPOS = (values.pos
            || this.documentFiltersService.settings.pos) as string;

        if (!posItems.includes(choosenPOS)) {
            [choosenPOS] = posItems;
            values.pos = choosenPOS;
        }

        filters.push({
            name: 'pos',
            label: 'POS',
            value: choosenPOS,
            options: posItems.map(p => ({ name: p, value: p })),
            disableOnEdit: false,
        });
    }

    private defineLosFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const choosenLOS = (values.los
            || this.documentFiltersService.settings.los
            || DEFAULT_LOS[0]) as number;

        filters.push({
            name: 'los',
            label: 'LOS',
            value: choosenLOS,
            options: DEFAULT_LOS.map(l => ({
                name: String(l),
                value: l,
            })),
            disableOnEdit: false,
        });
    }

    private defineMealTypeFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;
        const { mealTypes } = this.mealTypesService;
        const { mealTypeId: mainMealTypeId } = this.ratesFiltersService.settings;

        const mealTypeId = mainMealTypeId === ANY_MEAL_TYPE.id
            ? ANY_MEAL_TYPE.name
            : this.mealTypesService.getMealType(mainMealTypeId)!.name;

        const choosenMealType = (values.meal_type || mealTypeId) as string;

        filters.push({
            name: 'meal_type',
            label: 'Meal Type',
            value: choosenMealType,
            options: mealTypes
                .map((mealType: MealTypeModel) => ({
                    value: mealType.name,
                    name: mealType.displayName,
                })),
            disableOnEdit: false,
        });
    }

    private defineRoomTypeFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;
        const { rooms } = this.roomTypesService;
        const { roomTypeId: mainRoomTypeId } = this.ratesFiltersService.settings;

        const roomTypeId = mainRoomTypeId === -1
            ? ANY_ROOM_TYPE.name
            : this.roomTypesService
                .getRoomType(mainRoomTypeId)!.name;

        const choosenRoomType = (values.room_type || roomTypeId) as number;

        filters.push({
            name: 'room_type',
            label: 'Room Type',
            value: choosenRoomType,
            options: rooms
                .map((room: RoomTypeModel) => ({
                    value: room.name,
                    name: room.name,
                })),
            disableOnEdit: false,
        });
    }

    private defineOccupancyFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const choosenOccupancy = values.occupancy
            || this.ratesFiltersService.settings.numberOfGuests
            || this.userSettingsService.defaultFilters.numberOfGuests;

        filters.push({
            name: 'occupancy',
            label: 'Number of guests',
            value: choosenOccupancy as number,
            options: Array
                .from({ length: 10 })
                .map((_, i) => i + 1)
                .map(e => ({
                    value: e,
                    name: e + (e > 1 ? ' guests' : ' guest'),
                })),
            disableOnEdit: false,
        });
    }

    private definePriceTypeFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const choosenPriceType = values.price
            || this.ratesFiltersService.settings.priceType
            || this.userSettingsService.defaultFilters.price;

        filters.push({
            name: 'price',
            label: 'Price',
            value: choosenPriceType as PRICE_TYPE,
            options: [
                {
                    name: 'Lowest',
                    value: PRICE_TYPE.LOWEST,
                },
                {
                    name: 'Lowest Flex',
                    value: PRICE_TYPE.LOWEST_FLEX,
                },
                {
                    name: 'Best Flex',
                    value: PRICE_TYPE.BEST_FLEX,
                },
                {
                    name: 'Non Refundable',
                    value: PRICE_TYPE.NON_REFUNDABLE,
                },
            ],
            disableOnEdit: false,
        });

        let priceTypeValue = values.price_type as string;

        if (!priceTypeValue) {
            switch (this.documentFiltersService.priceShown) {
                case PRICE_SHOWN.TOTAL:
                    priceTypeValue = 'total_price';
                    break;
                case PRICE_SHOWN.NET:
                    priceTypeValue = 'net_price';
                    break;
                default:
                    priceTypeValue = 'shown_price';
                    break;
            }
        }

        filters.push({
            name: 'price_type',
            label: 'Price Type',
            value: priceTypeValue,
            options: [
                { value: 'shown_price', name: PRICE_SHOWN.SHOWN },
                { value: 'net_price', name: PRICE_SHOWN.NET },
                { value: 'total_price', name: PRICE_SHOWN.TOTAL },
            ],
            disableOnEdit: false,
        });
    }

    private getMainValue(compareKey: string): (string | number) | (string | number)[] {
        const { filters: values = {} } = this.form;
        const mainValue = values[keyMap[compareKey]];

        switch (compareKey) {
            case 'mealType':
                return mainValue !== ANY_MEAL_TYPE.name
                    ? this.mealTypesService.getMealType(mainValue as string)?.id || -1
                    : '';

            case 'roomType':
                return mainValue !== ANY_ROOM_TYPE.name
                    ? this.roomTypesService.getRoomType(mainValue as string)?.id || -1
                    : '';

            default:
                return mainValue as string | number;
        }
    }

    private getCompareValue(compareKey: string) {
        const { filters: values = {} } = this.form;

        let compareValue = !values.comparison_to
            ? this.ratesAnalysisFiltersService.comparisonValues.map(v => v.value)
            : (values.comparison_to as ComplexPair<(string | number)[]>).value as (string | number)[];

        if (!values.comparison_to) {
            switch (compareKey) {
                case 'mealType':
                    compareValue = compareValue
                        .map(v => this.mealTypesService.getMealType(v)!.name);
                    break;

                case 'roomType':
                    compareValue = compareValue
                        .map(v => this.roomTypesService.getRoomType(v)!.name);
                    break;

                default: break;
            }
        }

        return compareValue;
    }

    private getActualProviderList() {
        const { filters: values = {} } = this.form;

        const compset = this.compsetsService.getCompset(values.compset as string)
            || this.compsetsService.currentCompset;
        return (compset?.rateProviders || [])
            .filter(p => !['all', 'cheapest'].includes(p));
    }

    private defineCompareFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const compareKey = !values.comparison_to
            ? analysisKeyMap[this.ratesAnalysisFiltersService.comparisonKey]
            : (values.comparison_to as ComplexPair<(string | number)[]>).key as string;

        const providerList = this.getActualProviderList();
        const compareValue = this.getCompareValue(compareKey);

        const compareValues = Object
            .entries(analysisKeyMap)
            .reduce((acc, pair) => {
                const [compareKey, filterKey] = pair;
                const mainValue = this.getMainValue(filterKey)!;

                switch (filterKey) {
                    case 'mealType':
                        acc[filterKey] = () => this.ratesAnalysisFiltersService
                            .getFilterItemsExceptValue(compareKey, mainValue)
                            .map(item => ({
                                name: item.name,
                                value: this.mealTypesService.getMealType(item.value)!.name,
                            }));
                        break;

                    case 'roomType':
                        acc[filterKey] = () => this.ratesAnalysisFiltersService
                            .getFilterItemsExceptValue(compareKey, mainValue)
                            .map(item => ({
                                name: item.name,
                                value: item.name,
                            }));
                        break;
                    case 'source':
                        acc[filterKey] = () => providerList
                            .filter(p => p !== mainValue)
                            .map(value => ({
                                name: this.providersService.getProviderLabel(value),
                                value,
                            }));
                        break;

                    default:
                        acc[filterKey] = () => this.ratesAnalysisFiltersService
                            .getFilterItemsExceptValue(compareKey, mainValue);
                        break;
                }

                return acc;
            }, {} as Record<string, () => Item[]>);

        filters.push({
            name: 'comparison_to',
            label: 'Compare to',
            value: [compareKey, compareValue],
            options: [
                compareList,
                Object
                    .values(compareValues)
                    .map(getItems => getItems()),
            ],
            maxSelected: 2,
            disableOnEdit: [false, false],
        });
    }
}
